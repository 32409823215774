export class ExportData {

    trunkName: string;
    startLocation: string;
    endLocation: string;
    numberOfLinks: number;
    lengthIncrement: number;
    exportLengthIncrements: Array<ExportLengthIncrements>;
    metric: string;
    metricShortHand: string;
    uncertainity: number;
    cableType: string;
    waveLength1: string;
    waveLength2: string;
    year: number;

    constructor() {
        this.trunkName = "";
        this.startLocation = "";
        this.endLocation = "";
        this.numberOfLinks = 0;
        this.lengthIncrement = 0;
        this.exportLengthIncrements = new Array<ExportLengthIncrements>()
        this.metric = "Meters";
        this.metricShortHand = "m";
        this.waveLength1 = "";
        this.waveLength2 = "";
        this.uncertainity = 0;
        this.year = 0;
        this.cableType = "";

    }
}

export class ExportLengthIncrements {

    trunkName: string;
    startLocation: string;
    endLocation: string;
    cableType: string;
    length: number;
    loss850: string;
    loss1310: string;
    

    constructor() {
        this.trunkName = "";
        this.startLocation = "";
        this.endLocation = "";
        this.cableType = "";
        this.length = 0;
        this.loss850 = "";
        this.loss1310 = "";
        
    }
}