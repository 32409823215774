
export class FiberPerformance {

    IsMultiMode: boolean;
    TrunkName: string;
    StartLocation: string;
    EndLocation: string;
    CableSelection: string;
    SwitchType: string;
    CableLength: number;
    Uncertainty: number;
    NumberOfLinks: number;
    LengthMetric: string;
    StateChange: string;

    constructor() {
        this.IsMultiMode = true;
        this.TrunkName = "";
        this.StartLocation = "";
        this.EndLocation = "";
        this.CableSelection = "lazrSpeedOm5Wb";
        this.SwitchType = "";
        this.CableLength = 100;
        this.Uncertainty = 0.25;
        this.NumberOfLinks = 0;
        this.LengthMetric = 'meters';
        this.StateChange = '';
    }
}
