import { useEffect, useRef, useState } from 'react';
import { Module } from '../../Models/Module';
import './Modules.css'

function Modules(props: any) {

    const selectedModules = useRef<Array<Module>>([])

    const modulesInfo = useRef<Array<Module>>([]);
    const [modulesInfoState, setModulesInfoState] = useState(modulesInfo.current);

    const isFlipped = useRef(false);
    const [isFlippedState, setIsFlippedState] = useState(isFlipped.current);

    const [flipBtnTitleState, setFlipBtnTitleState] = useState("Flip Components");

    function onClickModulePatchCord(event: any, temp: Module) {
        if (selectedModules.current.length < 12) {

            var newModule = new Module()

            newModule.CableSelection = temp.CableSelection
            newModule.CableType = temp.CableType
            newModule.ConnectorType = temp.ConnectorType
            newModule.EndPoint = temp.EndPoint
            newModule.IsEnabled = temp.IsEnabled
            newModule.Mean = temp.Mean
            newModule.ModuleId = temp.ModuleId
            newModule.ModuleName = temp.ModuleName
            newModule.Src = temp.Src
            newModule.StandardDeviation = temp.StandardDeviation
            newModule.StartPoint = temp.StartPoint

            if (isFlippedState === true) {
                newModule.IsFlipped = true
            }
            else {
                newModule.IsFlipped = false
            }

            selectedModules.current.push(newModule);
            props.updateFromModules(selectedModules)
        }
    }

    useEffect(() => {
        selectedModules.current = [...props.selectedModulesUpdated]
    }, [props.selectedModulesUpdated])

    useEffect(() => {
        if (props.modulesInfo.length > 0) {
            setModulesInfoState(props.modulesInfo)
        }
    }, [props.modulesInfo])

    useEffect(() => {
        //if (props.calculationParameters !== undefined) {
        //    for (let i = 0; i < selectedModules.current.length; i++) {
        //        for (let j = 0; j < modulesInfoState.length; j++) {
        //            if (modulesInfoState[j].ModuleName === selectedModules.current[i].ModuleName &&
        //                modulesInfoState[j].CableSelection === props.calculationParameters.CableSelection) {
        //                selectedModules.current[i] = modulesInfoState[j]
        //            }
        //        }
        //    }
        //    props.updateFromModules(selectedModules)
        //}
    }, [props.calculationParameters])

    useEffect(() => {
        //console.log()
    }, [modulesInfoState])

    useEffect(() => {
        if (props !== undefined && props.isClearAll !== undefined && props.isClearAll === true) {
            selectedModules.current = []

            let tempResult = [];
            for (let i = 0; i < props.modulesInfo.length; i++) {
                let newModule = new Module();
                newModule.ModuleId = props.modulesInfo[i].ModuleID;
                newModule.ModuleName = props.modulesInfo[i].ModuleName;
                newModule.CableType = props.modulesInfo[i].CableType;
                newModule.StartPoint = props.modulesInfo[i].StartPoint;
                newModule.EndPoint = props.modulesInfo[i].EndPoint;
                newModule.ConnectorType = props.modulesInfo[i].ConnectorType;
                newModule.Src = "ModulesPatchCords/" + props.modulesInfo[i].ModuleName + ".svg";
                newModule.CableSelection = props.modulesInfo[i].CableSelection;
                newModule.Mean = props.modulesInfo[i].Mean;
                newModule.StandardDeviation = props.modulesInfo[i].StandardDeviation;
                newModule.IsEnabled = true;
                tempResult.push(newModule)
            }

            modulesInfo.current = tempResult;
            setModulesInfoState(modulesInfo.current)
        }
    }, [props.isClearAll])

    function onFlipBtnClicked() {
        isFlipped.current = !isFlipped.current
        if (isFlipped.current === true) {
            setIsFlippedState(true)
            setFlipBtnTitleState("Back to Original")
        }
        else {
            setIsFlippedState(false)
            setFlipBtnTitleState("Flip Components")
        }
    }

    return (

        <div className="row" >
            
            <div className="col-md-12" style={{
                backgroundColor: "#f4f4f4", paddingTop: "1%", paddingLeft: "1%", paddingBottom: "1%", paddingRight: "1%"
            }} >
                <div className="row" style={{ backgroundColor: "#323232", paddingTop: "0.5%", paddingBottom: "1%", textAlign: "center", margin: "auto" }}>
                    <div className="col-md-12 d-flex align-items-center" style={{ justifyContent: "space-between" }} >
                        <h6 style={{ marginBottom: "0", color: "white" }}>Components</h6>
                        <button className="btn btn-light" onClick={onFlipBtnClicked}>{flipBtnTitleState}</button>
                    </div>
                </div>
                <div className="row" style={{
                    margin: "auto", width: "100%", paddingTop: "1%", backgroundColor: "white", paddingRight: "5%"
                }}>
                    {modulesInfoState.filter(item => item.ConnectorType === 'Module').map((temp: Module) => (
                        <div>
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === true &&
                                temp.CableType === "MM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === false &&
                                <div>
                                    <img alt="Module" className="moduleImg" src={"ModulesPatchCords/" + temp.ModuleName + ".svg"}
                                        onClick={(e) => onClickModulePatchCord(e, temp)} />
                                </div>
                            }
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === true &&
                                temp.CableType === "MM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === true &&
                                <div>
                                    <img alt="Module" className="moduleImg" src={"ModulesPatchCords/" + temp.ModuleName + "-Flipped" + ".svg"}
                                        onClick={(e) => onClickModulePatchCord(e, temp)} />
                                </div>
                            }

                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === false &&
                                temp.CableType === "SM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === false &&
                                <div>

                                    <img alt="Module" className="moduleImg" src={"ModulesPatchCords/" + temp.ModuleName + ".svg"}
                                        onClick={(e) => onClickModulePatchCord(e, temp)} />
                                </div>

                            }
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === false &&
                                temp.CableType === "SM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === true &&
                                <div>

                                    <img alt="Module" className="moduleImg" src={"ModulesPatchCords/" + temp.ModuleName + "-Flipped" + ".svg"}
                                        onClick={(e) => onClickModulePatchCord(e, temp)} />
                                </div>

                            }
                        </div>
                    ))}
                </div>
                <div className="row" style={{
                    margin: "auto", width: "100%", paddingTop: "1%", backgroundColor: "white", paddingRight: "5%"
                }}>

                    {modulesInfoState.filter(item => item.ConnectorType === 'PatchCord').map((temp: Module) => (
                        <div>
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === true &&
                                temp.CableType === "MM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === false &&
                                <img alt="Module" className="patchCordImg" src={"ModulesPatchCords/" + temp.ModuleName + ".svg"}
                                    onClick={(e) => onClickModulePatchCord(e, temp)} />
                            }
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === true &&
                                temp.CableType === "MM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === true &&
                                <img alt="Module" className="patchCordImg" src={"ModulesPatchCords/" + temp.ModuleName + "-Flipped" + ".svg"}
                                    onClick={(e) => onClickModulePatchCord(e, temp)} />
                            }
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === false &&
                                temp.CableType === "SM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === false &&
                                <img alt="Module" className="patchCordImg" src={"ModulesPatchCords/" + temp.ModuleName + ".svg"}
                                    onClick={(e) => onClickModulePatchCord(e, temp)} />
                            }
                            {props.calculationParameters !== undefined && props.calculationParameters.IsMultiMode === false &&
                                temp.CableType === "SM" && (temp.CableSelection === props.calculationParameters.CableSelection ||
                                    temp.CableSelection === "") && temp.IsEnabled === true && isFlippedState === true &&
                                <img alt="Module" className="patchCordImg" src={"ModulesPatchCords/" + temp.ModuleName + "-Flipped" + ".svg"}
                                    onClick={(e) => onClickModulePatchCord(e, temp)} />
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
}

export default Modules;