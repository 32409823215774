export class CableSelectionRadio {

    lazrSpeedOm5Wb: boolean;
    lazrSpeed550Om4: boolean;
    teraSpeedSm: boolean;

    constructor() {
        this.lazrSpeedOm5Wb = true;
        this.lazrSpeed550Om4 = false;
        this.teraSpeedSm = false;
    }
}