import React, { useEffect, useRef, useState } from 'react';
import { FiberPerformance } from '../../Models/FiberPerformance';
import './CalculationParameters.css'
import 'reactjs-popup/dist/index.css';
import ModalExport from '../Modal/ModalExport'
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import { RadioButton } from '@syncfusion/ej2-react-buttons';
import { CableSelectionRadio } from '../../Models/CableSelectionRadio';
import html2canvas from 'html2canvas';



export function CalculationParameters(props: any) {

    const isMultimode = useRef(true)
    const calculationValues = new FiberPerformance()

    const performanceCalculatorValues = useRef<FiberPerformance>(new FiberPerformance());
    const [performanceCalculatorValuesState, setPerformanceCalculatorValuesState] = useState(performanceCalculatorValues.current);

    const [smAlert, setSmAlert] = useState(false);
    const [smAlertMessageTitle, setSmAlertMessageTitle] = useState("");
    const [smAlertMessageDesc, setSmAlertMessageDesc] = useState("");

    const handleClose = () => setSmAlert(false);
    const handleShow = () => setSmAlert(true);

    //const cableSelectionRadio = useRef <CableSelectionRadio>(new CableSelectionRadio())

    const [radioButtonEvent, setRadioButtonEvent] = useState(new CableSelectionRadio());

    const radioEventId = useRef("")

    function onChangeCableTypeRadio(event: any) {

        //changeCableType(event)
        radioEventId.current = event.target.id

        if (props.selectedModules.length > 0) {
            setSmAlertMessageTitle("Confirmation");
            setSmAlertMessageDesc("Changing Cable Selection will clear your configuration. Would you like to Continue?");
            setSmAlert(true)
        }
        else {
            changeCableType(event.target.id)
        }
    }

    function setRadioSelectionValues(selectedRadio: string) {
        let tempCableSelection = new CableSelectionRadio()
        if (selectedRadio === "teraSpeedSm") {
            tempCableSelection.teraSpeedSm = true
            tempCableSelection.lazrSpeed550Om4 = false
            tempCableSelection.lazrSpeedOm5Wb = false
        }
        else if (selectedRadio === "lazrSpeed550Om4") {
            tempCableSelection.teraSpeedSm = false
            tempCableSelection.lazrSpeed550Om4 = true
            tempCableSelection.lazrSpeedOm5Wb = false
        }
        else if (selectedRadio === "lazrSpeedOm5Wb") {
            tempCableSelection.teraSpeedSm = false
            tempCableSelection.lazrSpeed550Om4 = false
            tempCableSelection.lazrSpeedOm5Wb = true
        }
        setRadioButtonEvent(tempCableSelection)
    }

    useEffect(() => {
        //console.log(radioButtonEvent)
    }, [radioButtonEvent])

    function changeCableType(id: any) {
        calculationValues.StateChange = "IsMultiMode"
        if (id === "teraSpeedSm") {
            const setVal = false
            isMultimode.current = setVal;
            calculationValues.IsMultiMode = setVal;

            const cableSelc = "teraSpeedSm"
            calculationValues.CableSelection = cableSelc
        }
        else if (id === "lazrSpeed550Om4") {
            const setVal = true

            isMultimode.current = setVal;
            calculationValues.IsMultiMode = setVal;

            const cableSelc = "lazrSpeed550Om4"
            calculationValues.CableSelection = cableSelc
        }
        else {
            const setVal = true
            isMultimode.current = setVal;
            calculationValues.IsMultiMode = setVal;

            const cableSelc = "lazrSpeedOm5Wb"
            calculationValues.CableSelection = cableSelc
        }
        setPerformanceCalculatorValuesState(calculationValues)
        setRadioSelectionValues(calculationValues.CableSelection)
        props.updateFromCalculationParameters(calculationValues)
    }

    useEffect(() => {
        //console.log(props.selectedModules)
    }, [props.selectedModules])

    function onChangeLengthMetric(event: any) {

        calculationValues.StateChange = "LengthMetric"

        if (event.target.id === "metersRadioType") {
            calculationValues.LengthMetric = "meters";
        }
        else {
            calculationValues.LengthMetric = "feet";
        }
        setPerformanceCalculatorValuesState(calculationValues)

        props.updateFromCalculationParameters(calculationValues)
    }


    function onChangeLength(event: any) {

        calculationValues.StateChange = "CableLength"

        let newLength = event.target.value;
        if (newLength === '') {
            newLength = 0
        }
        calculationValues.CableLength = parseInt(newLength)
        setPerformanceCalculatorValuesState(calculationValues)

        props.updateFromCalculationParameters(calculationValues)
    }

    function onChangeUncertainty(event: any) {

        calculationValues.StateChange = "Uncertainty"

        let uncertainty = event.target.value;
        if (uncertainty === '') {
            uncertainty = 0
        }
        if (uncertainty === Math.floor(uncertainty)) {
            calculationValues.Uncertainty = 0
            props.updateFromCalculationParameters(calculationValues)
        }
        else {
            calculationValues.Uncertainty = parseFloat(uncertainty)
            props.updateFromCalculationParameters(calculationValues)
        }
        setPerformanceCalculatorValuesState(calculationValues)

    }

    function onCableSelectionCancelClick(event: any) {
        setRadioSelectionValues(calculationValues.CableSelection)
        setSmAlert(false)
    }

    function onCableSelectionYesClick(event: any) {
        changeCableType(radioEventId.current)
        props.clearAllUpdateFromCalculationParameters(true)
        setSmAlert(false)
    }

    return (
        <div>
            <Modal
                show={smAlert}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{smAlertMessageTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {smAlertMessageDesc}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCableSelectionCancelClick}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={onCableSelectionYesClick}>Yes</Button>
                </Modal.Footer>
            </Modal>
            <div className="row pb-4 mb-4 mb" style={{ borderBottom: "2px solid #666666" }}>
                <div className="col-md-3">
                    <h6>
                        Cable Selection
                    </h6>
                    <hr />
                    <div id="cableSelections" onChange={onChangeCableTypeRadio}>
                        <label className="custom-control custom-radio">
                            <input id="lazrSpeedOm5Wb" name="cableTypeRadioStacked" type="radio" checked={radioButtonEvent.lazrSpeedOm5Wb} className="custom-control-input" />
                            <span className="custom-control-indicator"></span>
                            <span className="custom-control-description">LazrSPEED OM5 WB</span>
                        </label>
                        <label className="custom-control custom-radio" style={{ marginTop: "2%" }}>
                            <input id="lazrSpeed550Om4" name="cableTypeRadioStacked" type="radio" checked={radioButtonEvent.lazrSpeed550Om4} className="custom-control-input" />
                            <span className="custom-control-indicator"></span>
                            <span className="custom-control-description">LazrSPEED 550 OM4</span>
                        </label>
                        <label className="custom-control custom-radio" style={{ marginTop: "2%" }}>
                            <input id="teraSpeedSm" name="cableTypeRadioStacked" checked={radioButtonEvent.teraSpeedSm} type="radio" className="custom-control-input" />
                            <span className="custom-control-indicator"></span>
                            <span className="custom-control-description">TeraSPEED SM</span>
                        </label>
                    </div>
                </div>
                <div className="col-md-5">
                    <h6>Cable Attributes</h6>
                    <hr />
                    <form>
                        <div id="cableAttributes" className="row">
                            <div className="col-md-6 mb-3">
                                <label>Cable Length</label>
                                <input type="number" id="inputHelpBlock" placeholder="Enter a Length" defaultValue="100"
                                    className="form-control" aria-describedby="helpBlock" onChange={onChangeLength} />
                            </div>
                            <div className="col-md-6 mt-4" onChange={onChangeLengthMetric}>
                                <label className="custom-control custom-radio">
                                    <input id="metersRadioType" name="radio-stacked" defaultChecked type="radio" className="custom-control-input" />
                                    <span className="custom-control-indicator"></span>
                                    <span className="custom-control-description">meters</span>
                                </label>
                                <label className="custom-control custom-radio">
                                    <input id="feetRadioType" name="radio-stacked" type="radio" className="custom-control-input" />
                                    <span className="custom-control-indicator"></span>
                                    <span className="custom-control-description">feet</span>
                                </label>
                            </div>
                        </div>
                        <div id="uncertaintyValueImage" className="p-2 pl-3 bg-gray-100 d-flex align-items-center">
                            <label>Uncertainty Value</label>
                            <input type="text" id="txtUncertainty" onChange={onChangeUncertainty}
                                className="form-control ml-2 mr-2" aria-describedby="helpBlock" defaultValue="0.25" style={{ width: '100px' }} />
                            <label>dB</label>
                        </div>
                    </form>
                </div>
                <hr />
                <div className="col-md-4">
                    <div>
                        <h6>
                            Loss Calculations
                        </h6>
                        <hr />
                        <div id="lossCalculations" className="row" style={{ textAlign: "center", margin: "Auto" }} >
                            <div className="col-md-12 mb-3" style={{
                                border: '2px solid var(--primary)', textAlign: "center", paddingTop: "3%", backgroundColor: "white"
                            }}>
                                <div className="row" >
                                    <div className="col-md-7">
                                        {isMultimode.current === true &&
                                            <h6 style={{ color: "black" }}>850 nm Loss</h6>}
                                        {isMultimode.current === false &&
                                            <h6 style={{ color: "black" }}>1310 nm Loss</h6>}
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-8" style={{ textAlign: "center", border: '2px solid #707070', height: "30px" }} >
                                            <label>{props.lossValue1.current?.toString()}</label>
                                        </div>
                                        <label style={{ marginTop: "4%" }}>&nbsp;dB</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        {isMultimode.current === true &&
                                            <h6 style={{ color: "black" }}>1300 nm Loss</h6>}
                                        {isMultimode.current === false &&
                                            <h6 style={{ color: "black" }}>1550 nm Loss</h6>}
                                    </div>
                                    <div className="row" >
                                        <div className="col-md-8" style={{ textAlign: "center", border: '2px solid #707070', height: "30px" }} >
                                            <label>{props.lossValue2.current?.toString()}</label>
                                        </div>
                                        <label style={{ marginTop: "4%" }}>&nbsp;dB</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 text-right mt-3">
                            <ModalExport />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculationParameters;

