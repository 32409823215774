export class Application {

    applicationDataID: number;
    applicationName: string;
    modeType: string;
    cableType: string;
    maxLength: number;
    columnSequence: number;
    rowSequence: number;
    connectorLoss: number;

    constructor() {
        this.applicationDataID = 0;
        this.applicationName = "";
        this.modeType = "";
        this.cableType = "";
        this.maxLength = 0.00;
        this.columnSequence = 0.00;
        this.rowSequence = 0.00;
        this.connectorLoss = 0.0000;
    }
}