export class AppAssuranceResults {

    appAssuranceResultsID: number;
    applicationName: string;
    cableType: string;
    mode: string;
    length: number;
    connectorLoss: number;

    constructor() {
        this.appAssuranceResultsID = 0;
        this.applicationName = "";
        this.cableType = "";
        this.mode = "";
        this.length = 0;
        this.connectorLoss = 0.00000;
    }
}