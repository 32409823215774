import React from 'react';
import './BreadcrumbsStyles.css'
function Breadcrumbs(props: any) {
    var commscopeHomeUrl = process.env.REACT_APP_SERVER_URL_HOME ? process.env.REACT_APP_SERVER_URL_HOME.toString() : ""
    var commscopeCalculator = process.env.REACT_APP_SERVER_URL_CALCULATORS ? process.env.REACT_APP_SERVER_URL_CALCULATORS.toString() : ""

    return (
        <div >
            <div id="headerDiv" className=".band-xs-short bg-gradient-blue-dark text-white p-4">
                <div className="hero-head">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ol className="breadcrumb breadcrumb-inverse" id="breadCrumbList" style={{ marginTop: '20px' }}>
                                    <li className="breadcrumb-item"><a href={commscopeHomeUrl}>Home</a></li>
                                    <li className="breadcrumb-item"><a href={commscopeCalculator}>Calculators</a></li>
                                    <li className="breadcrumb-item"><a>Ultra Low Loss Fiber Performance Calculator</a></li>
                                </ol>
                            </div>
                        </div>
                        <div className="hero-body"></div>
                        <div className="hero-footer mb-3">
                            <div className="hero-title">
                                <h1 className="display-4">Ultra Low Loss Fiber Performance Calculator</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                
            </div>
        </div>
    );
}
export default Breadcrumbs;
