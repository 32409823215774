import React from 'react';
import './App.css';
import Header from './Components/Header';
import Breadcrumbs from './Components/Breadcrumbs/Breadcrumbs';
import Footer from './Components/Footer';
import ReactDOM from 'react-dom';
import MainGrid from './Components/CalculatorGrid/MainGrid';
import { Font } from '@react-pdf/renderer'
import Analytics from './Components/Analytics';
Font.register({ family: 'Univia-pro,sans-serif', src: "https://use.typekit.net/qqu8qhw.css" });
Font.register({ family: 'Rajdhani-Regular', src: "/Assets/Rajdhani" });
Font.register({ family: 'Rajdhani-Regular', src: "/Assets/Rajdhani/Rajdhani-Light.ttf" });
Font.register({ family: 'Rajdhani-Regular', src: "/Assets/Rajdhani/Rajdhani-Regular.ttf" });
//import url("https://use.typekit.net/qqu8qhw.css");


function App() {

    return (
        <div className="App">
            <Header />
            <Analytics/>
            <Breadcrumbs />
            <MainGrid />
            <div>
            </div>
            <Footer />
        </div>
    );
}
export default App;
