export class Module {

    ModuleId: number;
    ModuleName: string;
    CableType: string;
    StartPoint: string;
    EndPoint: string;
    ConnectorType: string;
    CableSelection: string;
    Src: string;
    IsEnabled: boolean;
    Mean: number;
    StandardDeviation: number;
    IsFlipped: boolean;
   
    constructor() {
        this.ModuleId = 0;
        this.ModuleName = "";
        this.CableType = "";
        this.StartPoint = "";
        this.EndPoint = "";
        this.ConnectorType = "";
        this.CableSelection = "";
        this.IsEnabled = true;
        this.IsFlipped = true;
        this.Src = "";
        this.Mean = 0.00000;
        this.StandardDeviation = 0.00000;
    }
}