import CalculationParameters from "../CalculationParameters/CalculationParameters";
import Modules from "../Modules/Modules";
import Applications from "../Applications/Applications";
import { useEffect, useRef, useState } from "react";
import './MainGrid.css'
import { FiberPerformance } from "../../Models/FiberPerformance";
import { Module } from "../../Models/Module";
import eventBus from "../../EventBus";

export function MainGrid() {
    const selectedModules = useRef<Array<Module>>([]);

    const circles = useRef<Array<number>>([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const [selectedModulesState, setSelectedModulesState] = useState<Array<Module>>([]);

    const performanceCalculatorValues = useRef<FiberPerformance>(new FiberPerformance());
    const [performanceCalculatorValuesState, setPerformanceCalculatorValuesState] = useState(performanceCalculatorValues.current);

    const [connectorLoss, setConnectorLoss] = useState(0.0000);

    const modulesInfo = useRef<Array<Module>>([]);
    const [modulesInfoState, setModulesInfoState] = useState(modulesInfo.current);

    const isClearAll = useRef(false)
    const [isClearAllState, setIsClearAllState] = useState(false);

    const lossValue1 = useRef("0.00")
    const lossValue2 = useRef("0.00")



    useEffect(() => {
        //console.log(selectedModulesState)

    }, [selectedModulesState]);

    useEffect(() => {
        if (modulesInfoState === undefined) {
            GetModulesData();
        }
        else if (modulesInfoState.length === 0) {
            GetModulesData();
        }

    }, [modulesInfoState]);

    useEffect(() => {
        calculateUll()
    },[])

    function GetModulesData() {
        var getAppUrl = process.env.REACT_APP_SERVER_URL_GET_MODULES ? process.env.REACT_APP_SERVER_URL_GET_MODULES.toString() : "";
        const getData = async () => await fetch(getAppUrl, {})
            .then(res => res.json())
            .then(
                (result) => {
                    let tempResult = [];
                    for (let i = 0; i < result.length; i++) {
                        let newModule = new Module();
                        newModule.ModuleId = result[i].moduleID;
                        newModule.ModuleName = result[i].moduleName;
                        newModule.CableType = result[i].cableType;
                        newModule.StartPoint = result[i].startPoint;
                        newModule.EndPoint = result[i].endPoint;
                        newModule.ConnectorType = result[i].connectorType;
                        newModule.Src = "ModulesPatchCords/" + result[i].moduleName + ".svg";
                        newModule.CableSelection = result[i].cableSelection;
                        newModule.Mean = result[i].mean;
                        newModule.StandardDeviation = result[i].standardDeviation;
                        newModule.IsEnabled = true;
                        tempResult.push(newModule)
                    }

                    modulesInfo.current = tempResult;
                    setModulesInfoState(modulesInfo.current)
                },
                (error) => {
                    //console.log(error);
                }
            );
        getData();
    }

    function updateFromModules(updatedModules: any) {

        isClearAll.current = false
        setIsClearAllState(false)

        const newModules = [...updatedModules.current]
        selectedModules.current = newModules
        setSelectedModulesState(selectedModules.current)

        for (let i = 0; i < circles.current.length; i++) {
            if (circles.current[i] === selectedModules.current.length) {
                circles.current.splice(i, 1);
            }
        }

        calculateUll()
    }

    function calculateUll() {

        let totalMean = 0.0000;
        let totalStdDeviation = 0.0000;

        /* Lossconn=ConnQty*ConnMeanLoss+2*(ConnQty*ConnStdDev^2)^0.5 */

        //Calculate Connector Loss
        for (let i = 0; i < selectedModules.current.length; i++) {
            for (let j = 0; j < modulesInfo.current.length; j++) {

                var array = selectedModules.current[i].Src.split('/');
                var lastsegment = array[array.length - 1].split('.');
                var moduleName = lastsegment[0]

                if (moduleName === modulesInfo.current[j].ModuleName) {
                    if (performanceCalculatorValues.current.IsMultiMode === true && modulesInfo.current[j].CableType === "MM") {
                        totalStdDeviation = (totalStdDeviation) + Math.pow(modulesInfo.current[j].StandardDeviation, 2)
                        totalMean = totalMean + modulesInfo.current[j].Mean
                    }
                    else if (performanceCalculatorValues.current.IsMultiMode === false && modulesInfo.current[j].CableType === "SM") {
                        totalStdDeviation = (totalStdDeviation) + Math.pow(modulesInfo.current[j].StandardDeviation, 2)
                        totalMean = totalMean + modulesInfo.current[j].Mean
                    }
                }
            }
        }

        let connectorLoss: number = Math.round((totalMean + (3 * Math.sqrt(totalStdDeviation))) * 100)/100
        setConnectorLoss(connectorLoss)

        //Calculate Cable Loss
        //Multimode
        //for 850 - cable length * 0.003 = cable loss
        //ull for 850 = cable loss + ull
        //for 1300 - cable length * 0.001 = cable loss

        //if multimode use this
        let cableLoss850 = 0
        let cableLoss1300 = 0;

        if (performanceCalculatorValues.current.CableSelection === 'teraSpeedSm') {
            cableLoss850 = performanceCalculatorValues.current.CableLength * 0.0005
            cableLoss1300 = performanceCalculatorValues.current.CableLength * 0.0005
        }
        else {
            cableLoss850 = performanceCalculatorValues.current.CableLength * 0.003
            cableLoss1300 = performanceCalculatorValues.current.CableLength * 0.001
        }

        //if single mode 0.0005 for both

        //incase value is in feet then we mutliply with 0.3048
        //add uncertainty to this

        if (performanceCalculatorValues.current.LengthMetric === "feet") {
            cableLoss850 = cableLoss850 * 0.3048
            cableLoss1300 = cableLoss1300 * 0.3048
        }

        let uncertainty = 0.00
        uncertainty = performanceCalculatorValues.current.Uncertainty

        let tempCableLoss850 = cableLoss850 + uncertainty
        let tempCableLoss1300 = cableLoss1300 + uncertainty

        lossValue1.current = (connectorLoss + tempCableLoss850).toFixed(2)
        lossValue2.current = (connectorLoss + tempCableLoss1300).toFixed(2)
    }

    function clearAllUpdateFromCalculationParameters() {
        onClickClearAll(null)
    }

    function updateFromCalculationParameters(calculationValues: FiberPerformance) {

        const tempFibPerforObj = new FiberPerformance()

        tempFibPerforObj.CableLength = performanceCalculatorValues.current.CableLength
        tempFibPerforObj.IsMultiMode = performanceCalculatorValues.current.IsMultiMode
        tempFibPerforObj.TrunkName = performanceCalculatorValues.current.TrunkName
        tempFibPerforObj.StartLocation = performanceCalculatorValues.current.StartLocation
        tempFibPerforObj.EndLocation = performanceCalculatorValues.current.EndLocation
        tempFibPerforObj.CableSelection = performanceCalculatorValues.current.CableSelection
        tempFibPerforObj.SwitchType = performanceCalculatorValues.current.SwitchType
        tempFibPerforObj.Uncertainty = performanceCalculatorValues.current.Uncertainty
        tempFibPerforObj.LengthMetric = performanceCalculatorValues.current.LengthMetric

        if (calculationValues.StateChange === "IsMultiMode") {
            tempFibPerforObj.IsMultiMode = calculationValues.IsMultiMode
            tempFibPerforObj.CableSelection = calculationValues.CableSelection
        }
        else if (calculationValues.StateChange === "LengthMetric") {
            tempFibPerforObj.LengthMetric = calculationValues.LengthMetric
        }
        else if (calculationValues.StateChange === "CableLength") {
            tempFibPerforObj.CableLength = calculationValues.CableLength
        }
        else if (calculationValues.StateChange === "Uncertainty") {
            tempFibPerforObj.Uncertainty = calculationValues.Uncertainty
        }

        performanceCalculatorValues.current = tempFibPerforObj
        setPerformanceCalculatorValuesState(tempFibPerforObj)

        calculateUll()

    }

    useEffect(() => {
        eventBus.dispatch("calculationParameters", { message: performanceCalculatorValuesState });
    }, [performanceCalculatorValuesState])

    useEffect(() => {
        eventBus.dispatch("selectedModules", { message: selectedModulesState });
    }, [selectedModulesState])

    useEffect(() => {
        eventBus.dispatch("modulesInfo", { message: modulesInfoState });
    }, [selectedModulesState])

    function onClickClearAll(event: any) {
        selectedModules.current = []
        setSelectedModulesState(selectedModules.current)

        isClearAll.current = true
        setIsClearAllState(true)

        circles.current = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        calculateUll()

    }

    function onClickRemove(event: any, module: Module, index: number) {
        var tempModules = [...selectedModules.current]
        tempModules.splice(index, 1)
        selectedModules.current = [...tempModules]
        setSelectedModulesState(selectedModules.current)

        circles.current = []

        for (let i = tempModules.length + 1; i < 13; i++) {
            circles.current.push(i)
        }

        calculateUll()
    }

    return (
        <div>
            <div className="container mt-4">
                <p>
                    Calculate link or channel loss and determine the supported applications and max lengths for the configuration. The configuration and results can be exported as PDF.
                </p>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <CalculationParameters updateFromCalculationParameters={updateFromCalculationParameters}
                            lossValue1={lossValue1} lossValue2={lossValue2} selectedModules={selectedModulesState}
                            clearAllUpdateFromCalculationParameters={clearAllUpdateFromCalculationParameters} />
                    </div>
                </div>
                <p>
                    You can also select components to configure connections below and add the field configuration below it. The components will show connections between units and will calculate loss based on the units and length selected and inputed.
                </p>
            </div>

            <div className="container" style={{
                backgroundColor: "#f4f4f4"
            }} >
                <div className="row" style={{ backgroundColor: "white" }}>
                    <div className="col-md-12" >
                        <div>
                            <Modules updateFromModules={updateFromModules} calculationParameters={performanceCalculatorValuesState}
                                isClearAll={isClearAllState} modulesInfo={modulesInfoState} selectedModulesUpdated={selectedModulesState} />
                        </div>
                    </div>
                </div>
                <div className="col-md-12" style={{ marginTop: "1%", textAlign: "right" }}>
                    <button className="btn btn-default" onClick={onClickClearAll}>Clear All</button>
                </div>
                <div id="selectedModulesId">
                    {selectedModules.current.length > 0 &&
                        <div className="row strip">
                            <div>
                                <img src="Receiver-Left.svg" />
                            </div>
                            <div className="row col-md-11" style={{ position: "relative", marginLeft:"-1.5rem" }}>
                                {selectedModules.current.map((module: Module, index: number) => (
                                    <div>
                                        {index > 0 && performanceCalculatorValues.current.CableSelection === "lazrSpeedOm5Wb" &&
                                            <div className="transmitterStripWire" style={{ backgroundColor: "#78BE21" }}></div>
                                        }
                                        {index > 0 && performanceCalculatorValues.current.CableSelection === "lazrSpeed550Om4" &&
                                            <div className="transmitterStripWire" style={{ backgroundColor: "#05C3DD" }}></div>
                                        }
                                        {index > 0 && performanceCalculatorValues.current.CableSelection === "teraSpeedSm" &&
                                            <div className="transmitterStripWire" style={{ backgroundColor: "#0039C1" }}></div>
                                        }

                                        {module.ConnectorType === "Module" && module.IsFlipped === true &&
                                            <div >
                                                <div>
                                                    <img alt="Transmitter" className="transmitterStripImg"
                                                        src={"ModulesPatchCords/" + module.ModuleName + "-Flipped" + ".svg"} />
                                                </div>
                                                <div>
                                                    <button data-html2canvas-ignore="true" className="btn btn-default removeBtn" onClick={(e) => onClickRemove(e, module, index)}>Remove</button>
                                                </div>
                                            </div>
                                        }
                                        {module.ConnectorType === "PatchCord" && module.IsFlipped === true &&
                                            <div >
                                                <div>
                                                    <img alt="Transmitter" className="transmitterStripImg" style={{ marginTop:"3.55rem" }}
                                                        src={"ModulesPatchCords/" + module.ModuleName + "-Flipped" + ".svg"} />
                                                </div>
                                                <div>
                                                    <button data-html2canvas-ignore="true" className="btn btn-default removeBtn" onClick={(e) => onClickRemove(e, module, index)}>Remove</button>
                                                </div>
                                            </div>
                                        }

                                        {module.ConnectorType === "Module" && module.IsFlipped === false &&
                                            <div >
                                                <div>
                                                    <img alt="Transmitter" className="transmitterStripImg"
                                                        src={"ModulesPatchCords/" + module.ModuleName + ".svg"} />
                                                </div>
                                                <div>
                                                    <button data-html2canvas-ignore="true" className="btn btn-default removeBtn" onClick={(e) => onClickRemove(e, module, index)}>Remove</button>
                                                </div>
                                            </div>
                                        }
                                        {module.ConnectorType === "PatchCord" && module.IsFlipped === false &&
                                            <div>
                                                <div>
                                                    <img alt="Transmitter" className="transmitterStripImg" style={{ marginTop: "3.5rem" }}
                                                        src={"ModulesPatchCords/" + module.ModuleName + ".svg"} />
                                                </div>
                                                <div>
                                                    <button data-html2canvas-ignore="true" className="btn btn-default removeBtn" onClick={(e) => onClickRemove(e, module, index)}>Remove</button>
                                                </div>

                                            </div>
                                        }

                                    </div>
                                ))}

                                <div>
                                    <img alt="Receiver-Right" src="Receiver-Right.svg" />
                                </div>

                                {
                                    circles.current.map((title: any) => (
                                        <div className="dotAlignment">
                                            <span className="dot">{title}</span>
                                        </div>
                                    ))}

                            </div>
                        </div>
                    }
                    {selectedModules.current.length === 0 &&
                        <div className="row strip">
                            <div className="col-md-1">
                                <img alt="Receiver" src="Receiver.svg" />
                            </div>
                            <div className="row col-md-10">
                                {
                                    circles.current.map((title: any) => (
                                        <div className="col-md-1">
                                            <span className="dot">{title}</span>
                                        </div>
                                    ))}
                            </div>
                            <div className="col-md-1" style={{ marginTop: "-1.5%" }}>
                                <img alt="Receiver" src="Receiver.svg" />
                            </div>
                        </div>
                    }
                </div>
            </div >
            <div className="container">
                    <Applications connectorLoss={connectorLoss} selectedModules={selectedModulesState} calculationParameters={performanceCalculatorValuesState} isClearAll={isClearAllState} />
                <p className="disclaimer"><strong>DISCLAIMER</strong>: These calculations are provided for guidance purposes only. They are provided as is and should not be relied upon without consultation with and supervision of experienced personnel and a local CommScope representative. CommScope makes no representations or warranties of any kind related to these tools, whether express or implied, and specifically disclaims and excludes any representation or warranty of merchantability, quality, content, completeness, suitability, adequacy, accuracy, noninfringement or fitness for a particular purpose and any representation arising by usage of trade, course of dealing or course of performance. CommScope is under no obligation to issue any upgrades, update specifications or notify users of this tool that changes have been made. The user of these tools assumes all risks associated with such use, and CommScope hereby disclaims any and all liability for damages of any kind resulting from such use.</p>
            </div>


        </div>

    );
}

export default MainGrid;