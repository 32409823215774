import { useEffect, useRef, useState } from 'react';

import './Applications.css'
import { Application } from "../../Models/Applications";
import { Module } from '../../Models/Module';
import { EquationsHelper } from '../../EquationsHelper/EquationsHelper';
import { FiberPerformance } from '../../Models/FiberPerformance';
import { AppAssuranceResults } from '../../Models/AppAssuranceResults';
import App from '../../App';

export function Applications(props: any) {

    const [applications, setApplications] = useState<Array<Application>>([]);
    const allApplications = useRef<Array<Application>>([]);

    const [appAssuranceResults, setAppAssuranceResults] = useState<Array<AppAssuranceResults>>([]);
    const [sortedAppAssuranceResults, setSortedAppAssuranceResults] = useState<Array<{}>>([]);

    const [selectedModulesState, setSelectedModulesState] = useState<Array<Module>>([]);

    const performanceCalculatorValues = useRef<FiberPerformance>(new FiberPerformance());
    const prevPerformanceCalculatorValues = useRef<FiberPerformance>(new FiberPerformance());
    const [performanceCalculatorValuesState, setPerformanceCalculatorValuesState] = useState(performanceCalculatorValues.current);

    const [isClearAllState, setIsClearAllState] = useState(false);

    const connectorLossRef = useRef(0.000);
    const [connectorLoss, setConnectorLoss] = useState(connectorLossRef.current);

    const isPerformCalculationsCalled = useRef(false)

    //useEffect(() => {
    //    if (applications === undefined) {
    //        //getPendingRequestsData()
    //        GetApplications();
    //        //GetAppAssuranceResults();
    //    }
    //    else if (applications.length === 0) {
    //        GetApplications();
    //        //GetAppAssuranceResults();
    //    }

    //}, [applications]);

    useEffect(() => {
        GetApplications()
        performCalculationsForMaxLength()
        isPerformCalculationsCalled.current = true
    }, [])

    useEffect(() => {
        if (props.selectedModules != undefined && props.selectedModules.length > 0) {
            setSelectedModulesState(props.selectedModules)
        }
    }, [props.selectedModules])

    useEffect(() => {
        if (props.isClearAll === true) {
            performCalculationsForMaxLength()
        }
    }, [props.isClearAll])

    useEffect(() => {

        if (prevPerformanceCalculatorValues.current.CableLength !== props.calculationParameters.CableLength ||
            prevPerformanceCalculatorValues.current.CableSelection !== props.calculationParameters.CableSelection ||
            prevPerformanceCalculatorValues.current.EndLocation !== props.calculationParameters.EndLocation ||
            prevPerformanceCalculatorValues.current.IsMultiMode !== props.calculationParameters.IsMultiMode ||
            prevPerformanceCalculatorValues.current.LengthMetric !== props.calculationParameters.LengthMetric ||
            prevPerformanceCalculatorValues.current.NumberOfLinks !== props.calculationParameters.NumberOfLinks ||
            prevPerformanceCalculatorValues.current.StartLocation !== props.calculationParameters.StartLocation ||
            prevPerformanceCalculatorValues.current.StateChange !== props.calculationParameters.StateChange ||
            prevPerformanceCalculatorValues.current.SwitchType !== props.calculationParameters.SwitchType ||
            prevPerformanceCalculatorValues.current.TrunkName !== props.calculationParameters.TrunkName ||
            prevPerformanceCalculatorValues.current.Uncertainty !== props.calculationParameters.Uncertainty
        ) {
            setPerformanceCalculatorValuesState(props.calculationParameters)
            performCalculationsForMaxLength()
            prevPerformanceCalculatorValues.current = props.calculationParameters
        }

    }, [props.calculationParameters])

    useEffect(() => {
        //console.log(props.connectorLoss)
        if (connectorLossRef.current !== props.connectorLoss) {
            connectorLossRef.current = (Math.round((props.connectorLoss + Number.EPSILON) * 1000) / 1000)
            setConnectorLoss(connectorLossRef.current)
            performCalculationsForMaxLength()
        }
    }, [props.connectorLoss])

    //useEffect(() => {
    //    if (appAssuranceResults.length !== undefined && applications.length > 0) {
    //        performCalculationsForMaxLength()
    //    }
    //}, appAssuranceResults)

    function GetApplications() {

        var getAppUrl = process.env.REACT_APP_SERVER_URL_GET_APPLICATIONS ? process.env.REACT_APP_SERVER_URL_GET_APPLICATIONS.toString() : "";
        const getData = async () => await fetch(getAppUrl, {})
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result);
                    let applicationsNew = new Array<Application>()

                    for (let i = 0; i < result.length; i++) {
                        let applicationTemp = new Application()
                        applicationTemp.applicationName = result[i].applicationName
                        applicationTemp.applicationDataID = result[i].applicationID
                        applicationTemp.modeType = result[i].mode
                        applicationTemp.cableType = result[i].cableType
                        applicationTemp.columnSequence = result[i].columnSequence
                        applicationTemp.rowSequence = result[i].rowSequence
                        applicationsNew.push(applicationTemp)
                    }

                    allApplications.current = applicationsNew
                },
                (error) => {
                    //console.log(error);
                }
            );

        getData();
    }

    function performCalculationsForMaxLength() {

        var getAppUrl = process.env.REACT_APP_SERVER_URL_GET_APPASSURANCE_RESULTS ? process.env.REACT_APP_SERVER_URL_GET_APPASSURANCE_RESULTS.toString() : "";
        const getData = async () => await fetch(getAppUrl + "?connectorLoss=" + connectorLossRef.current, {})
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    let tempResult = Array<AppAssuranceResults>();

                    for (let i = 0; i < result.length; i++) {

                        let newVal = new AppAssuranceResults()
                        newVal.appAssuranceResultsID = result[i].appAssuranceResultsID
                        newVal.applicationName = result[i].applicationName
                        newVal.cableType = result[i].cableType.trim()
                        newVal.mode = result[i].mode
                        newVal.length = result[i].length
                        newVal.connectorLoss = result[i].connectorLoss

                        tempResult.push(newVal)
                    }

                    let applicationsNew = new Array<Application>()

                    for (let i = 0; i < tempResult.length; i++) {
                        if (tempResult[i].applicationName.split(',').length > 1) {
                            for (let j = 0; j < tempResult[i].applicationName.split(',').length; j++) {
                                let applicationTemp = new Application()
                                applicationTemp.applicationName = tempResult[i].applicationName.split(',')[j]
                                applicationTemp.modeType = tempResult[i].mode
                                applicationTemp.cableType = tempResult[i].cableType
                                applicationTemp.connectorLoss = tempResult[i].connectorLoss

                                if (props.calculationParameters.LengthMetric === "feet") {
                                    applicationTemp.maxLength = (Math.round((tempResult[i].length * 3.281)))
                                }
                                else {
                                    applicationTemp.maxLength = tempResult[i].length
                                }

                                if (connectorLossRef.current > applicationTemp.connectorLoss) {
                                    applicationTemp.maxLength = 0
                                }

                                applicationsNew.push(applicationTemp)


                            }
                        }
                        else {

                            let applicationTemp = new Application()
                            applicationTemp.applicationName = tempResult[i].applicationName
                            applicationTemp.modeType = tempResult[i].mode
                            applicationTemp.cableType = tempResult[i].cableType
                            /* applicationTemp.maxLength = tempResult[i].length*/
                            if (props.calculationParameters.LengthMetric === "feet") {
                                applicationTemp.maxLength = (Math.round((tempResult[i].length * 3.281)))
                            }
                            else {
                                applicationTemp.maxLength = tempResult[i].length
                            }
                            applicationTemp.connectorLoss = tempResult[i].connectorLoss

                            if (connectorLossRef.current > applicationTemp.connectorLoss) {
                                applicationTemp.maxLength = 0
                            }

                            applicationsNew.push(applicationTemp)
                        }
                    }

                    for (let i = 0; i < allApplications.current.length; i++) {
                        var doesAppExists = false;
                        for (let j = 0; j < applicationsNew.length; j++) {
                            if (allApplications.current[i].applicationName === applicationsNew[j].applicationName
                                && allApplications.current[i].cableType === applicationsNew[j].cableType) {
                                doesAppExists = true
                                applicationsNew[j].columnSequence = allApplications.current[i].columnSequence
                                applicationsNew[j].rowSequence = allApplications.current[i].rowSequence
                                break;
                            }
                            else {
                                doesAppExists = false
                            }
                        }
                        if (doesAppExists === false) {
                            allApplications.current[i].maxLength = 0;
                            applicationsNew.push(allApplications.current[i])
                        }
                    }

                    setApplications(applicationsNew)
                },
                (error) => {
                    //console.log(error);
                }
            );

        getData();

        //let resArr = Object.assign([], ...appAssuranceResults.map((x) =>
        //    ({ [x.applicationName + ":" + x.cableType]: appAssuranceResults.filter(item => item.applicationName === x.applicationName && item.cableType === x.cableType) })));

        //let tempDictionaryArrayResults = new Array()
        //let applicationsNew = new Array<Application>()

        //Object.keys(resArr).forEach((key, index, values) => {

        //    let dict: { [applicationName: string]: AppAssuranceResults } = {}
        //    dict[key] = resArr[key]

        //    tempDictionaryArrayResults.push(dict)


        //})

        //setApplications(applicationsNew)

        //for (let i = 0; i < tempDictionaryArrayResults.length; i++) {
        //    let tempResultsDict = tempDictionaryArrayResults[i]
        //    for (let appName in tempResultsDict) {

        //        let value = tempResultsDict[appName]
        //        let tempClosestVal = value.sort((a: AppAssuranceResults, b: AppAssuranceResults) => {
        //            return Math.abs(a.connectorLoss - connectorLossRef.current) - Math.abs(b.connectorLoss - connectorLossRef.current);
        //        })[0]

        //        if (tempClosestVal.applicationName.split(',').length > 1) {
        //            for (let i = 0; i < tempClosestVal.applicationName.split(',').length; i++) {
        //                let applicationTemp = new Application()
        //                applicationTemp.applicationName = tempClosestVal.applicationName.split(',')[i]
        //                applicationTemp.modeType = tempClosestVal.mode
        //                applicationTemp.cableType = tempClosestVal.cableType

        //                if (props.calculationParameters.LengthMetric === "feet") {
        //                    applicationTemp.maxLength = (Math.round((tempClosestVal.length * 3.281)))
        //                }
        //                else {
        //                    applicationTemp.maxLength = tempClosestVal.length
        //                }

        //                applicationsNew.push(applicationTemp)
        //            }
        //        }
        //        else {
        //            let applicationTemp = new Application()
        //            applicationTemp.applicationName = tempClosestVal.applicationName
        //            applicationTemp.modeType = tempClosestVal.mode
        //            applicationTemp.cableType = tempClosestVal.cableType
        //            applicationTemp.maxLength = tempClosestVal.length

        //            applicationsNew.push(applicationTemp)
        //        }
        //    }
        //}

        //for (let i = 0; i < applications.length; i++) {
        //    let applicationTemp = new Application()

        //    applicationTemp.applicationName = applications[i].applicationName
        //    applicationTemp.modeType = applications[i].modeType
        //    applicationTemp.maxLength = 0
        //    applicationsNew.push(applicationTemp)
        //}

        //setApplications(applicationsNew)

        //setSortedAppAssuranceResults(tempDictionaryArrayResults)
    }

    return (
        <div>
            <div id="applicationsTable" className="p-3 mt-4 mb-4" style={{ border: "1px solid #888" }}>
                <div className="row">
                    <div className="col-7">
                        {performanceCalculatorValuesState.IsMultiMode === true &&
                            <h4 >MultiMode Applications Chart</h4>}

                        {performanceCalculatorValuesState.IsMultiMode === false &&
                            <h4 >Single-Mode Applications Chart</h4>}

                        <p>Compatible applications available for your selected configuration.</p>
                    </div>
                    <div className="col-md-5" style={{ position: 'relative', top: '-1.5rem' }} >
                        <div className="pt-4 pr-2 pb-2 pl-2 legend">

                            <h6 className="mb-0">Legend</h6>
                            <p><img alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" className="mr-1" />
                                Application supported. Distance within Limits.</p>

                            <img alt="FiberLinkLong" src="FiberLinkLong.PNG" className="mr-1" />
                            Application supported but link too long.

                        </div>
                    </div>
                </div>
                <div className="mm-app-chart">
                    <div className="row" >
                        <div className="col-md-4 border-right" >
                            <div className="row" >
                                <div className="col-md-2">
                                    <label style={{ paddingBottom: "1%" }}><b>  </b></label>
                                </div>
                                <div className="col-md-5 border-bottom">
                                    <label style={{ paddingBottom: "1%" }}><b> Application </b></label>
                                </div>
                                <div className="col-md-5 border-bottom" >
                                    {performanceCalculatorValuesState.LengthMetric === "meters" &&
                                        <label style={{ paddingBottom: "1%" }}><b> Max Length(m) </b></label>}
                                    {performanceCalculatorValuesState.LengthMetric === "feet" &&
                                        <label style={{ paddingBottom: "1%" }}><b> Max Length(ft) </b></label>}
                                </div>
                            </div>

                            {performanceCalculatorValuesState.IsMultiMode === true && applications.filter(item => item.modeType === 'MM'
                                && performanceCalculatorValuesState.CableSelection === item.cableType && item.columnSequence === 1).sort((a, b) => (a.rowSequence > b.rowSequence) ? 1 : -1).map((getData, index) =>
                                    <div className="row">
                                        {performanceCalculatorValuesState.CableLength <= getData.maxLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" />
                                            </div>
                                        }
                                        {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="FiberLinkLong" src="FiberLinkLong.PNG" />
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <label
                                                className="applicationsTableTitleLabel"
                                                key={getData.applicationDataID}>{getData.applicationName}</label>
                                        </div>
                                        <div className="col-md-4">
                                            {performanceCalculatorValuesState.CableLength <= getData.maxLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {getData.maxLength}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                )}
                            {performanceCalculatorValuesState.IsMultiMode === false && applications.filter(item => item.modeType === 'SM'
                                && performanceCalculatorValuesState.CableSelection === item.cableType && item.columnSequence === 1).sort((a, b) => (a.rowSequence > b.rowSequence) ? 1 : -1).map((getData, index) =>

                                    <div className="row">
                                        {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" />
                                            </div>
                                        }
                                        {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="FiberLinkLong" src="FiberLinkLong.PNG" />
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <label
                                                className="applicationsTableTitleLabel"
                                                key={getData.applicationDataID}>{getData.applicationName}</label>
                                        </div>
                                        <div className="col-md-4" style={{}}>
                                            {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {getData.maxLength}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="col-md-4 border-right" >
                            <div className="row" >
                                <div className="col-md-2">
                                    <label style={{ paddingBottom: "1%" }}><b>  </b></label>
                                </div>
                                <div className="col-md-5 border-bottom">
                                    <label style={{ paddingBottom: "1%" }}><b> Application </b></label>
                                </div>
                                <div className="col-md-5 border-bottom">
                                    {performanceCalculatorValuesState.LengthMetric === "meters" &&
                                        <label style={{ paddingBottom: "1%" }}><b> Max Length(m) </b></label>}
                                    {performanceCalculatorValuesState.LengthMetric === "feet" &&
                                        <label style={{ paddingBottom: "1%" }}><b> Max Length(ft) </b></label>}
                                </div>
                            </div>
                            {performanceCalculatorValuesState.IsMultiMode === true && applications.filter(item => item.modeType === 'MM'
                                && performanceCalculatorValuesState.CableSelection === item.cableType && item.columnSequence === 2).sort((a, b) => (a.rowSequence > b.rowSequence) ? 1 : -1).map((getData, index) =>
                                    <div className="row">
                                        {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" />
                                            </div>
                                        }
                                        {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="FiberLinkLong" src="FiberLinkLong.PNG" />
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <label
                                                className="applicationsTableTitleLabel"
                                                key={getData.applicationDataID}>{getData.applicationName}</label>
                                        </div>
                                        <div className="col-md-4" style={{}}>
                                            {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {getData.maxLength}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                )}
                            {performanceCalculatorValuesState.IsMultiMode === false && applications.filter(item => item.modeType === 'SM'
                                && performanceCalculatorValuesState.CableSelection === item.cableType && item.columnSequence === 2).sort((a, b) => (a.rowSequence > b.rowSequence) ? 1 : -1).map((getData, index) =>
                                    <div className="row">
                                        {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" />
                                            </div>
                                        }
                                        {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="FiberLinkLong" src="FiberLinkLong.PNG" />
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <label
                                                className="applicationsTableTitleLabel"
                                                key={getData.applicationDataID}>{getData.applicationName}</label>
                                        </div>
                                        <div className="col-md-4" style={{}}>
                                            {performanceCalculatorValuesState.LengthMetric === "meters" && getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {performanceCalculatorValuesState.LengthMetric === "meters" && getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {performanceCalculatorValuesState.LengthMetric === "feet" && getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {(Math.round((getData.maxLength)))}
                                                </label>
                                            }
                                            {performanceCalculatorValuesState.LengthMetric === "feet" && getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {(Math.round((getData.maxLength)))}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="col-md-4" >
                            <div className="row" >
                                <div className="col-md-2">
                                    <label style={{ paddingBottom: "1%" }}><b>  </b></label>
                                </div>
                                <div className="col-md-5 border-bottom">
                                    <label style={{ paddingBottom: "1%" }}><b> Application </b></label>
                                </div>
                                <div className="col-md-5 border-bottom">
                                    {performanceCalculatorValuesState.LengthMetric === "meters" &&
                                        <label style={{ paddingBottom: "1%" }}><b> Max Length(m) </b></label>}
                                    {performanceCalculatorValuesState.LengthMetric === "feet" &&
                                        <label style={{ paddingBottom: "1%" }}><b> Max Length(ft) </b></label>}
                                </div>
                            </div>
                            {performanceCalculatorValuesState.IsMultiMode === true && applications.filter(item => item.modeType === 'MM'
                                && performanceCalculatorValuesState.CableSelection === item.cableType && item.columnSequence === 3).sort((a, b) => (a.rowSequence > b.rowSequence) ? 1 : -1).map((getData, index) =>
                                    <div className="row">
                                        {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" />
                                            </div>
                                        }
                                        {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="FiberLinkLong" src="FiberLinkLong.PNG" />
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <label
                                                className="applicationsTableTitleLabel"
                                                key={getData.applicationDataID}>{getData.applicationName}</label>
                                        </div>
                                        <div className="col-md-4" style={{}}>
                                            {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {getData.maxLength}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                )}
                            {performanceCalculatorValuesState.IsMultiMode === false && applications.filter(item => item.modeType === 'SM'
                                && performanceCalculatorValuesState.CableSelection === item.cableType && item.columnSequence === 3).sort((a, b) => (a.rowSequence > b.rowSequence) ? 1 : -1).map((getData, index) =>
                                    <div className="row">
                                        {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="ApplicationSupported" src="ApplicationSupportedIcon.PNG" />
                                            </div>
                                        }
                                        {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                            <div className="col-md-2" style={{ paddingTop: "1%", textAlign: "right" }}>
                                                <img style={{ width: "80%" }} alt="FiberLinkLong" src="FiberLinkLong.PNG" />
                                            </div>
                                        }
                                        <div className="col-md-6">
                                            <label
                                                className="applicationsTableTitleLabel"
                                                key={getData.applicationDataID}>{getData.applicationName}</label>
                                        </div>
                                        <div className="col-md-4" style={{}}>
                                            {getData.maxLength >= performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength">
                                                    {getData.maxLength}
                                                </label>
                                            }
                                            {getData.maxLength < performanceCalculatorValuesState.CableLength &&
                                                <label className="applicationsTableMaxLength" style={{ color: '#EC1C24' }}>
                                                    {getData.maxLength}
                                                </label>
                                            }
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <br>
                </br >
            </div>
            <br>
            </br >
        </div>
    );

}

export default Applications;
