import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { pdf, PDFDownloadLink, usePDF, View } from '@react-pdf/renderer';
import { ExportData, ExportLengthIncrements } from '../../Models/ExportData';
import { Page, Document, StyleSheet, Image, Text } from "@react-pdf/renderer";
import { Font } from '@react-pdf/renderer'
import html2canvas from 'html2canvas';
import { FiberPerformance } from '../../Models/FiberPerformance';
import eventBus from '../../EventBus';
import { Module } from '../../Models/Module';
import PropTypes from 'prop-types'
import { wrap } from 'module';
const timers = require('timers-promises')

Font.register({ family: 'Univia-pro,sans-serif', src: "https://use.typekit.net/qqu8qhw.css" });
Font.register({ family: 'Rajdhani', src: "/Assets/Rajdhani" });
Font.register({ family: 'Rajdhani-Light', src: "/Assets/Rajdhani/Rajdhani-Light.ttf" });
Font.register({ family: 'Rajdhani-Regular', src: "/Assets/Rajdhani/Rajdhani-Regular.ttf" });
Font.register({ family: 'Rajdhani-Bold', src: "/Assets/Rajdhani/Rajdhani-Bold.ttf" });
Font.register({ family: 'Rajdhani-SemiBold', src: "/Assets/Rajdhani/Rajdhani-SemiBold.ttf" });
Font.register({ family: 'Rajdhani-Medium', src: "/Assets/Rajdhani/Rajdhani-Medium.ttf" });
Font.register({ family: 'Roboto', src: "/Assets/Roboto" });
Font.register({ family: 'Roboto-Regular', src: "/Assets/Roboto/Roboto-Regular.ttf" });
Font.register({ family: 'Roboto-Black', src: "/Assets/Roboto/Roboto-Black.ttf" });
Font.register({ family: 'Roboto-Medium', src: "/Assets/Roboto/Roboto-Medium.ttf" });
Font.register({ family: 'Roboto-Light', src: "/Assets/Roboto/Roboto-Light.ttf" });
Font.register({ family: 'Roboto-LightItalic', src: "/Assets/Roboto/Roboto-LightItalic.ttf" });
//import url("https://use.typekit.net/qqu8qhw.css");


type ModalExportState = {
    show: boolean,
    isLoading: boolean,
    isDivProjectDetailsVisible: boolean,
    isDivConfirmationVisible: boolean,
    isRefreshed: string,
    fileName: string,
    projectDetails: ExportData,
    performanceCalculatorValuesState: FiberPerformance,
    selectedModulesState: Array<Module>,
    moduleInfoState: Array<Module>
}

type ModalExportProps = {

}

class ModalExport extends React.Component<ModalExportProps, ModalExportState>{
    //private calculationParamters: React.RefObject<FiberPerformance>;
    //constructor(props:any) {
    //    super(props);
    //    this.calculationParamters = React.createRef();
    //}

    //const[performanceCalculatorValuesState, setPerformanceCalculatorValuesState] = useState(performanceCalculatorValues.current);

    state: ModalExportState = {
        show: false,
        isLoading: false,
        isDivProjectDetailsVisible: true,
        isDivConfirmationVisible: false,
        isRefreshed: "",
        fileName: "",
        projectDetails: new ExportData(),
        performanceCalculatorValuesState: new FiberPerformance(),
        selectedModulesState: new Array<Module>(),
        moduleInfoState: new Array<Module>(),
    };

    currentFormValues: ExportData

    constructor(props: any) {
        super(props)
        this.currentFormValues = new ExportData()
    }

    componentDidMount() {
        this.setState({ isLoading: true })

        eventBus.on("calculationParameters", (data: any) =>
            this.setState({ performanceCalculatorValuesState: data.message }, () => {
                this.currentFormValues.uncertainity = data.message.Uncertainty
                if (data.message.CableSelection === "teraSpeedSm") {
                    this.currentFormValues.cableType = "TeraSPEED SM"
                }
                else if (data.message.CableSelection === "lazrSpeed550Om4") {
                    this.currentFormValues.cableType = "LazrSPEED 550 OM4"
                }
                else {
                    this.currentFormValues.cableType = "LazrSPEED OM5 WB"
                }

                if (data.message.LengthMetric === "feet") {
                    this.currentFormValues.metric = "Feet"
                    this.currentFormValues.metricShortHand = 'ft'
                }
                else {
                    this.currentFormValues.metric = "Meters"
                    this.currentFormValues.metricShortHand = 'm'
                }

                this.setState({ isLoading: false })
            })
        );
        eventBus.on("selectedModules", (data: any) =>
            this.setState({ selectedModulesState: data.message })
        );
        eventBus.on("modulesInfo", (data: any) =>
            this.setState({ moduleInfoState: data.message })
        );
    }

    componentWillUnmount() {
        eventBus.remove("calculationParameters", null);
        eventBus.remove("selectedModules", null);
        eventBus.remove("calculationParameters", null);
    }

    handleClose = () => {
        this.setState(state => ({
            show: false
        }))
    }

    handleShow = () => {
        this.setState(state => ({
            show: true
        }))
    }

    displayExportPopUp() {

        this.currentFormValues!.trunkName = ''
        this.currentFormValues!.startLocation = ''
        this.currentFormValues!.endLocation = ''
        this.currentFormValues!.numberOfLinks = 0
        this.currentFormValues!.lengthIncrement = 0

        this.setState(state => ({
            fileName: "FPC-" + this.currentFormValues!.trunkName + ".pdf"
        }))

        if (this.currentFormValues!.cableType === 'TeraSPEED SM') {
            this.currentFormValues!.waveLength1 = '1310'
            this.currentFormValues!.waveLength2 = '1550'
        }
        else {
            this.currentFormValues!.waveLength1 = '850'
            this.currentFormValues!.waveLength2 = '1300'
        }

        this.currentFormValues.year = new Date().getFullYear()

        this.setState(state => ({
            show: true,
            isDivProjectDetailsVisible: true,
            isDivConfirmationVisible: false,
            isLoading: false
        }))
    }

    onChangeFormValues(event: any) {
        this.setState({ isLoading: true })
        //console.log(event.target.value)
        if (this.currentFormValues === undefined || this.currentFormValues === null) {
            this.currentFormValues = new ExportData()
        }

        if (event.target.id === "inputTrunkName") {
            this.currentFormValues!.trunkName = event.target.value
            this.setState(state => ({
                fileName: "FPC-" + this.currentFormValues!.trunkName + ".pdf"
            }))
        }
        else if (event.target.id === "inputStartLocation") {
            this.currentFormValues!.startLocation = event.target.value
        }
        else if (event.target.id === "inputEndLocation") {
            this.currentFormValues!.endLocation = event.target.value
        }
        else if (event.target.id === "inputNumberOfLinks") {
            this.currentFormValues!.numberOfLinks = event.target.value
        }
        else if (event.target.id === "inputLengthIncrement") {
            this.currentFormValues!.lengthIncrement = event.target.value
        }

        var tempNewStateVal = new ExportData()

        if (this.currentFormValues.numberOfLinks !== undefined && this.currentFormValues.lengthIncrement !== undefined
            && this.currentFormValues.numberOfLinks > 0 && this.currentFormValues.lengthIncrement > 0) {

            let totalMean = 0.0000;
            let totalStdDeviation = 0.0000;

            /* Lossconn=ConnQty*ConnMeanLoss+2*(ConnQty*ConnStdDev^2)^0.5 */

            //Calculate Connector Loss
            for (let i = 0; i < this.state.selectedModulesState.length; i++) {
                for (let j = 0; j < this.state.moduleInfoState.length; j++) {

                    var array = this.state.selectedModulesState[i].Src.split('/');
                    var lastsegment = array[array.length - 1].split('.');
                    var moduleName = lastsegment[0]

                    if (moduleName === this.state.moduleInfoState[j].ModuleName) {
                        if (this.state.performanceCalculatorValuesState.IsMultiMode === true && this.state.moduleInfoState[j].CableType === "MM") {
                            totalStdDeviation = (totalStdDeviation) + Math.pow(this.state.moduleInfoState[j].StandardDeviation, 2)
                            totalMean = totalMean + this.state.moduleInfoState[j].Mean
                        }
                        else if (this.state.performanceCalculatorValuesState.IsMultiMode === false && this.state.moduleInfoState[j].CableType === "SM") {
                            totalStdDeviation = (totalStdDeviation) + Math.pow(this.state.moduleInfoState[j].StandardDeviation, 2)
                            totalMean = totalMean + this.state.moduleInfoState[j].Mean
                        }
                    }
                }
            }

            let connectorLoss: number = totalMean + (3 * Math.sqrt(totalStdDeviation))
            //setConnectorLoss(connectorLoss)

            //Calculate Cable Loss
            //Multimode
            //for 850 - cable length * 0.003 = cable loss
            //ull for 850 = cable loss + ull
            //for 1300 - cable length * 0.001 = cable loss

            this.state.projectDetails.exportLengthIncrements = new Array<ExportLengthIncrements>()

            var temp = new Array<ExportLengthIncrements>()

            for (let i = 0; i <= this.currentFormValues.numberOfLinks; i++) {
                var exportLengthIncrements = new ExportLengthIncrements()
                exportLengthIncrements.length = (Number(this.state.performanceCalculatorValuesState.CableLength) + (Number(this.currentFormValues.lengthIncrement) * i))

                let cableLoss850 = 0
                let cableLoss1300 = 0

                if (this.currentFormValues.cableType === 'TeraSPEED SM') {
                    cableLoss850 = exportLengthIncrements.length * 0.0005
                    cableLoss1300 = exportLengthIncrements.length * 0.0005
                }
                else {
                    cableLoss850 = exportLengthIncrements.length * 0.003
                    cableLoss1300 = exportLengthIncrements.length * 0.001
                }

                //incase value is in feet then we mutliply with 0.3048
                //add uncertainty to this

                if (this.state.performanceCalculatorValuesState.LengthMetric === "feet") {
                    cableLoss850 = cableLoss850 * 0.3048
                    cableLoss1300 = cableLoss1300 * 0.3048
                }

                let uncertainty = 0.00
                uncertainty = this.state.performanceCalculatorValuesState.Uncertainty

                let tempCableLoss850 = cableLoss850 + uncertainty
                let tempCableLoss1300 = cableLoss1300 + uncertainty

                exportLengthIncrements.trunkName = this.currentFormValues.trunkName
                exportLengthIncrements.startLocation = this.currentFormValues.startLocation
                exportLengthIncrements.endLocation = this.currentFormValues.endLocation
                exportLengthIncrements.cableType = this.state.performanceCalculatorValuesState.CableSelection
                exportLengthIncrements.loss850 = (connectorLoss + tempCableLoss850).toFixed(2)
                exportLengthIncrements.loss1310 = (connectorLoss + tempCableLoss1300).toFixed(2)
                temp.push(exportLengthIncrements)
            }


            tempNewStateVal.exportLengthIncrements = temp
        }

        tempNewStateVal.trunkName = this.state.projectDetails.trunkName
        tempNewStateVal.startLocation = this.state.projectDetails.startLocation
        tempNewStateVal.endLocation = this.state.projectDetails.endLocation
        tempNewStateVal.numberOfLinks = this.state.projectDetails.numberOfLinks
        tempNewStateVal.lengthIncrement = this.state.projectDetails.lengthIncrement

        this.setState({ projectDetails: tempNewStateVal }, () => {
            timers.setTimeout(2000)
            this.setState({ isLoading: false })
        })

        //this.setState(state => ({
        //    projectDetails: temp
        //}), () => {
        //    if (temp.numberOfLinks !== undefined && temp.lengthIncrement !== undefined && temp.numberOfLinks > 0 && temp.lengthIncrement > 0) {
        //        this.calculateUll()
        //    }
        //})
    }

    createApplicationsTableImage = async () => {
        let element = document.getElementById('applicationsTable')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createSelectedModuleImage = async () => {
        let elementRemove = document.getElementById('removeBtn')!
        let element = document.getElementById('selectedModulesId')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createLossCalculationsImage = async () => {
        let element = document.getElementById('lossCalculations')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createCableSelectionsImage = async () => {
        let element = document.getElementById('cableSelections')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createCableAttributesImage = async () => {
        let element = document.getElementById('cableAttributes')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    createUncertaintyValueImage = async () => {
        let element = document.getElementById('uncertaintyValueImage')!,
            canvas = await html2canvas(element, { scale: 3 }),
            data = canvas.toDataURL('image/jpg'),
            link = document.createElement('a');

        return data;
    };

    styles = StyleSheet.create({
        page: {
            backgroundColor: '#fff',
            fontFamily: 'Helvetica',
            fontSize: 11,
            paddingTop: 30,
            paddingLeft: 50,
            paddingRight: 50,
            lineHeight: 1.5,
            flexDirection: 'column',
            paddingBottom: 50
        },
        logo: {
            height: '100px',
            width: '100px',
            marginBottom: -20,
            marginLeft: 400

        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 30,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
        },
        left: {
            // width: '33%',//<- working alternative
            flexGrow: 0,
            flexShrink: 1,
            flexBasis: 200,
        },

        right: {
            padding: 5,
            // width: '66%', //<- working alternative
            flexShrink: 1,
            flexGrow: 2,
        },

        row: { display: "flex", flexDirection: "row", fontSize: 9, width: 550 },
        column: {
            display: "flex", flexDirection: "column", flexGrow: 1, width: 300, paddingTop: 12,
            paddingBottom: 8
        },
        projectDetailsColumn: {
            display: "flex", flexDirection: "column", flexGrow: 1, width: 300, marginTop: -60,
            paddingBottom: 8
        },
        table: {
            width: '100%', height: '100%', marginBottom: 10
        },

        bold: {
            fontWeight: 'bold',
        },

        headerColumn1: {
            width: '29%', borderTop: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'center',
            paddingTop: '5px', borderBottom: '1px solid #000000', fontWeight: 'bold'
        },
        headerColumn2: {
            width: '29%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'center', paddingTop: '5px', borderBottom: '1px solid #000000', fontWeight: 'bold'
        },
        headerColumn3: {
            width: '29%', borderTop: '1px solid #000000', borderRight: '1px solid #000000', textAlign: 'center', paddingTop: '5px', borderBottom: '1px solid #000000', fontWeight: 'bold'
        },
        column1: {
            width: '29%', borderLeft: '1px solid #000000', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', paddingTop: 2
        },
        column2: {
            width: '29%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', paddingTop: 2
        },
        column3: {
            width: '29%', borderRight: '1px solid #000000', borderBottom: '1px solid #000000', textAlign: 'center', paddingTop: 2
        },
    });


    render() {
        const { projectDetails, show, isDivProjectDetailsVisible,
            isDivConfirmationVisible, fileName } = this.state

        const pdf = (
            <Document >
                <Page wrap size="A4" style={this.styles.page}>
                    <View >
                        <View style={this.styles.row}>
                            <View style={this.styles.column}>
                                <View ><Text style={{
                                    fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold", marginTop: -15
                                }}>
                                    Ultra Low Loss Fiber Performance Calculator
                                </Text ></View>
                                <View style={this.styles.logo} >


                                    <Image style={{ marginTop: -25 }} src={"CommScopeHeader.PNG"} />

                                </View>

                            </View>
                            <View style={this.styles.column}>
                                <View style={this.styles.logo} >


                                    <Image style={{}} src={"CommScopeHeader.PNG"} />

                                </View>


                            </View>
                        </View >
                    </View>

                    <View style={{ marginBottom: 60, marginTop: -85 }}>

                        <Text style={{ fontSize: "6px", fontFamily: "Roboto-Light", marginTop: 10 }}>

                            DISCLAIMER: These calculations are provided for guidance purposes only. They
                            are provided as is and should not be relied upon without consultation with
                            and supervision of experienced personnel and a local CommScope
                            representative. CommScope makes no representations or warranties of any kind
                            related to these tools, whether express or implied, and specifically
                            disclaims and excludes any representation or warranty of merchantability,
                            quality, content, completeness, suitability, adequacy, accuracy,
                            noninfringement or fitness for a particular purpose and any representation
                            arising by usage of trade, course of dealing or course of performance.
                            CommScope is under no obligation to issue any upgrades, update
                            specifications or notify users of this tool that changes have been made. The
                            user of these tools assumes all risks associated with such use, and
                            CommScope hereby disclaims any and all liability for damages of any kind
                            resulting from such use.

                        </Text >


                        <View ><Text style={{
                            fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold", marginTop: 10
                        }}>
                            Loss Calculation Result
                        </Text ></View>


                        <View style={{ marginTop: 0, fontFamily: "Roboto-Medium", fontSize: "8px" }} >
                            <View style={this.styles.row}>
                                <View style={this.styles.column}>
                                    <View > <Image style={{ marginLeft: 3, marginTop: 15, height: 60, width: 150 }} src={this.createCableSelectionsImage} /></View>

                                </View>
                                <View style={this.styles.column}>
                                    <View > <Image style={{ marginLeft: -35, marginTop: 15, height: 35, width: 190 }} src={this.createCableAttributesImage} /></View>
                                    <View > <Image style={{ marginLeft: -30, marginTop: 7, height: 18, width: 165 }} src={this.createUncertaintyValueImage} />  </View>
                                </View>
                                <View style={this.styles.column}>
                                    <View > <Image style={{ marginLeft: -10, marginTop: 15, height: 55, width: 150 }} src={this.createLossCalculationsImage} /></View>

                                </View>
                            </View>
                        </View>
                        <View >
                            <Image style={{ marginTop: 10 }} src={this.createSelectedModuleImage} />
                        </View>

                        <View >
                            <Image style={{ marginTop: 20 }} src={this.createApplicationsTableImage} />
                        </View>
                    </View>

                    <View style={{ position: 'absolute' }} fixed >
                        <Text style={{ fontSize: "8px", fontFamily: "Roboto-Regular", marginTop: 800, width: 600, textAlign: 'center' }}>

                            &copy;{this.currentFormValues.year} CommScope, Inc. All rights reserved. All trademarks identified by &#174; or &#8482; are registered trademarks, respectively, of CommScope.

                        </Text >
                    </View >
                </Page>
                <Page size="A4" style={this.styles.page} wrap={true}>
                    <View >
                        <View style={this.styles.row}>
                            <View style={this.styles.column}>
                                <View ><Text style={{
                                    fontSize: "15px", color: "#0039c1", fontFamily: "Rajdhani-Bold", marginTop: -15
                                }}>
                                    Ultra Low Loss Fiber Performance Calculator
                                </Text ></View>
                                <View style={this.styles.logo} >
                                    <Image style={{ marginTop: -25 }} src={"CommScopeHeader.PNG"} />
                                </View>
                            </View>
                            <View style={this.styles.column}>
                                <View style={this.styles.logo} >
                                    <Image style={{}} src={"CommScopeHeader.PNG"} />
                                </View>
                            </View>
                        </View>
                        <View>
                            <View style={{ fontFamily: "Roboto-Medium", fontSize: "8px" }} >
                                <View style={this.styles.row}>
                                    <View style={this.styles.projectDetailsColumn}>
                                        <View ><Text>Trunk Name: {this.currentFormValues.trunkName}</Text></View>
                                        <View ><Text>Cable Type: {this.currentFormValues.cableType}</Text></View>
                                        <View ><Text>Uncertainty Value: {this.currentFormValues.uncertainity} dB</Text></View>
                                    </View>
                                    <View style={this.styles.projectDetailsColumn}>
                                        <View ><Text>Start Location ("A" End): {this.currentFormValues.startLocation}</Text></View>
                                        <View ><Text>End Location ("Z" End): {this.currentFormValues.endLocation}</Text></View>
                                    </View>
                                    <View style={this.styles.projectDetailsColumn}>
                                        <View ><Text>Number of Links: {this.currentFormValues.numberOfLinks}</Text></View>
                                        <View ><Text>Length Increment: {this.currentFormValues.lengthIncrement} {this.currentFormValues.metricShortHand}</Text></View>
                                    </View>
                                </View>
                            </View>
                            <View style={this.styles.table} wrap={true}>
                                <View style={[this.styles.row, this.styles.bold]}>
                                    <Text style={this.styles.headerColumn1}>Overall Length({this.currentFormValues.metricShortHand})</Text>
                                    <Text style={this.styles.headerColumn2}>{this.currentFormValues.waveLength1} nm Loss</Text>
                                    <Text style={this.styles.headerColumn3}>{this.currentFormValues.waveLength2} nm Loss</Text>
                                </View>

                                {
                                    projectDetails.exportLengthIncrements.map((lengthIncrementCalcs: ExportLengthIncrements) => (
                                        <View style={this.styles.row}>
                                            <View style={this.styles.column1}>
                                                <View ><Text style={{}}>{lengthIncrementCalcs.length}</Text></View>
                                            </View>
                                            <View style={this.styles.column2}>
                                                <View ><Text>{lengthIncrementCalcs.loss850}</Text></View>

                                            </View>
                                            <View style={this.styles.column3}>
                                                <View ><Text>{lengthIncrementCalcs.loss1310}</Text></View>

                                            </View>
                                        </View>
                                    ))}
                            </View >

                        </View >
                    </View>
                    <View style={{ position: 'absolute' }} fixed >
                        <Text style={{ fontSize: "8px", fontFamily: "Roboto-Regular", marginTop: 800, width: 600, textAlign: 'center' }}>

                            &copy;{this.currentFormValues.year} CommScope, Inc. All rights reserved. All trademarks identified by &#174; or &#8482; are registered trademarks, respectively, of CommScope.

                        </Text >
                    </View >
                </Page>
            </Document>
        );

        return (
            <>
                <button
                    id="btnExportToPDF"
                    className="btn btn-primary"
                    onClick={this.displayExportPopUp.bind(this)}>
                    Export
                </button>

                <Modal show={show} onHide={this.handleClose.bind(this)}>
                    <Modal.Body>
                        <div className="container">
                            {isDivProjectDetailsVisible &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="primary">Project Details - Export Data</h6>
                                            <hr />
                                            <form >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Number of Links</label>
                                                        <input type="number" id="inputNumberOfLinks" placeholder="Enter a Number"
                                                            className="form-control form-control-sm" aria-describedby="helpBlock" onChange={this.onChangeFormValues.bind(this)} />
                                                        <div>
                                                            <p className="disclaimer">
                                                                Creates multiple trunks of different lengths.
                                                            </p>
                                                        </div >
                                                    </div>
                                                    <div className="col-md-12">
                                                        <label>Length Increment</label>
                                                        <input type="number" id="inputLengthIncrement"
                                                            className="form-control form-control-sm" aria-describedby="helpBlock" onChange={this.onChangeFormValues.bind(this)} />
                                                        <div>
                                                            <p className="disclaimer">
                                                                Used to indicate the difference from one trunk length to next.
                                                            </p>
                                                        </div >
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Trunk Name</label>
                                                        <input type="text" id="inputTrunkName" placeholder="Enter your Trunk's Name"
                                                            onChange={this.onChangeFormValues.bind(this)} className="form-control form-control-sm" aria-describedby="helpBlock" />
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Start Location ("A" End)</label>
                                                        <input type="text" id="inputStartLocation" placeholder="Name of Start location" onChange={this.onChangeFormValues.bind(this)}
                                                            className="form-control form-control-sm" aria-describedby="helpBlock" />
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>End Location ("Z" End)</label>
                                                        <input type="text" id="inputEndLocation" placeholder="Name of End Location" onChange={this.onChangeFormValues.bind(this)}
                                                            className="form-control form-control-sm" aria-describedby="helpBlock" />
                                                    </div>
                                                   
                                                    <div className="col-md-12">
                                                       
                                                        <div className='download-link'>
                                                            <PDFDownloadLink
                                                                document={pdf}
                                                                fileName={fileName} className="btn btn-primary">
                                                                {({ blob, url, loading, error }) =>
                                                                    loading ? "Loading . . ." : "Export"
                                                                }
                                                            </PDFDownloadLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <hr />
                                        <br>
                                        </br >
                                    </div>
                                </div>
                            }
                            {isDivConfirmationVisible &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="primary">Project Details - Export Data</h6>
                                            <hr style={{ width: "100%" }}>
                                            </hr>
                                            <form >
                                                <h4 style={{ textAlign: "center" }}>
                                                    Your data file has been exported.
                                                </h4>
                                                <br>
                                                </br>

                                                <div className="col-md-12" style={{ marginTop: "3%", textAlign: "center" }}>
                                                    <Button style={{ marginTop: "3%", textAlign: "center" }} variant="secondary" onClick={this.handleClose}>
                                                        Close
                                                    </Button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default ModalExport;